import { uniqueId } from 'lodash-es'

// 定义 TabbarItem 接口
export interface TabbarItem {
  pagePath: string
  active: boolean
  title: string
  icon: string
  iconPath: string
  selectedIconPath: string
  iconType: 'wot' | 'local'
  module: 'default' | 'rentalManage' | 'mealAdmin'
}

const list = [
  {
    text: '首页',
    iconPath: 'static/tabbar/home.png',
    selectedIconPath: 'static/tabbar/home_cur.png',
    pagePath: 'pages/index/Index',
    iconType: 'local',
    module: 'default',
  },
  {
    text: '商城',
    iconPath: 'static/tabbar/shop.png',
    selectedIconPath: 'static/tabbar/shop_cur.png',
    pagePath: 'pages/goods/GoodsList',
    iconType: 'local',
    module: 'default',
  },
  {
    text: '购物车',
    iconPath: 'static/tabbar/cart.png',
    selectedIconPath: 'static/tabbar/cart_cur.png',
    pagePath: 'pages/cart/Cart',
    iconType: 'local',
    module: 'default',
  },

  {
    text: '停车优惠',
    iconPath: 'static/tabbar/ticket.png',
    selectedIconPath: 'static/tabbar/ticket_cur.png',
    pagePath: 'pages/coupon/CouponPage',
    iconType: 'local',
    module: 'default',
  },
  {
    text: '我的',
    iconPath: 'static/tabbar/my.png',
    selectedIconPath: 'static/tabbar/my_cur.png',
    pagePath: 'pages/profile/Profile',
    iconType: 'local',
    module: 'default',
  },

  {
    text: '首页',
    pagePath: 'pages/index/Index',
    iconPath: 'static/tabbar/home.png',
    selectedIconPath: 'static/tabbar/home_cur.png',
    iconType: 'local',
    module: 'mealAdmin',
  },
  {
    text: '订单',
    pagePath: 'pages/community/store/StoreOrder',
    iconPath: 'static/tabbar/order.png',
    selectedIconPath: 'static/tabbar/order_cur.png',
    iconType: 'local',
    module: 'mealAdmin',
  },
  {
    text: '工作台',
    pagePath: 'pages/community/workbench/Workbench',
    iconPath: 'static/tabbar/workbench.png',
    selectedIconPath: 'static/tabbar/workbench_cur.png',
    iconType: 'local',
    module: 'mealAdmin',
  },

  {
    text: '首页',
    pagePath: 'pages/index/Index',
    iconPath: 'static/tabbar/home.png',
    selectedIconPath: 'static/tabbar/home_cur.png',
    iconType: 'local',
    module: 'rentalManage',
  },
  {
    text: '房东',
    pagePath: 'pages/rental-manage/Index',
    icon: 'home',
    iconType: 'wot',
    module: 'rentalManage',
  },
  {
    text: '房产',
    pagePath: 'pages/rental-manage/building/BuildingHome',
    icon: 'app',
    iconType: 'wot',
    module: 'rentalManage',
  },
  {
    text: '我的',
    pagePath: 'pages/rental-manage/mine/Mine',
    icon: 'star',
    iconType: 'wot',
    module: 'rentalManage',
  },
]
const adaptTabbarList = (rawList: any[]): TabbarItem[] => {
  return rawList.map((item, index) => ({
    ...item,
    active: item.active ?? false,
    iconType: item.iconType || 'wot',
    module: item.module || 'default',
    uniqueId: uniqueId(),
  }))
}

const tabbarList = ref(adaptTabbarList(list))
export function useTabbar() {
  // const tabbarList = ref(adaptTabbarList(tabBar.list)) //bug 放在这里有bug

  // 当前活跃tabbar
  const activeTabbar = computed(() => {
    const item = tabbarList.value.find((item) => item.active)
    return item || tabbarList.value[0]
  })

  // 根据模块划分出页面路径
  const modulePagePaths = computed(() => {
    const map: Record<TabbarItem['module'], string[]> = {
      default: [],
      rentalManage: [],
      mealAdmin: [],
    }
    tabbarList.value.forEach((item) => {
      map[item.module].push(item.pagePath)
    })
    return map
  })

  // 获取当前页面路径（根据你的项目实际实现方式，替换为动态路径获取方法）
  const getCurrentPagePath = (): string => {
    const pages = getCurrentPages() // UniApp 提供的 API，获取当前页面栈
    return pages[pages.length - 1]?.route || '' // 当前页面路径
  }

  // 判断当前页面属于哪个模块
  const currentModule = computed(() => {
    const currentPagePath = getCurrentPagePath()
    return (
      (Object.entries(modulePagePaths.value).find(([_, paths]) =>
        paths.includes(currentPagePath),
      )?.[0] as TabbarItem['module']) || 'default'
    )
  })

  // 根据当前模块过滤出对应的 tabbar 项
  const filteredTabbarList = computed(() => {
    return tabbarList.value.filter((item) => item.module === currentModule.value)
  })

  // 设置某个 tabbar 项为激活状态
  const setTabbarItemActive = (pagePath: string) => {
    tabbarList.value.forEach((item) => {
      // 对两个首页做特别处理
      if (item.pagePath === 'pages/index/Index' && pagePath === '/') {
        item.active = true
        return
      }
      if (item.pagePath === pagePath) {
        item.active = true
      } else {
        item.active = false
      }
    })
  }

  return {
    tabbarList, // 原始 tabbar 列表
    activeTabbar,
    filteredTabbarList, // 当前模块对应的 tabbar 列表
    currentModule, // 当前模块
    setTabbarItemActive, // 设置激活状态
  }
}
