import { createSSRApp } from 'vue'
import App from './App.vue'
import store from './store'
import 'virtual:uno.css'
import './assets/styles/global.scss'
import './assets/styles/variable.css'
// import wx from 'weixin-js-sdk'
import { routeInterceptor, requestInterceptor, prototypeInterceptor } from './interceptors'
import { getRootSize } from './utils/common'

export function createApp() {
  const app = createSSRApp(App)
  // 将微信 SDK 挂载到 Vue 实例上
  // app.config.globalProperties.$wx = wx
  // 计算根字体大小并挂载到 Vue 实例上
  const rootSize = getRootSize()
  app.config.globalProperties.$rootSize = rootSize // 将根字体大小挂载到 Vue 实例上
  // 将 pxToRem 挂载到 Vue 实例上，直接使用缓存的 rootSize
  app.config.globalProperties.$px2rem = (size) => {
    const remSize = (size / 16) * app.config.globalProperties.$rootSize
    return remSize
  }
  // 忽略自定义元素
  app.config.compilerOptions.isCustomElement = (tag) => tag.startsWith('wx-open-launch-weapp')
  app.use(store)
  app.use(routeInterceptor)
  app.use(requestInterceptor)
  app.use(prototypeInterceptor)
  return {
    app,
  }
}
