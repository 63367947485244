<template>
  <wd-config-provider :theme-vars="themeVars" custom-style="min-height: 100vh">
    <!-- <wd-navbar
      :title="activeTabbar.title"
      safe-area-inset-top
      placeholder
      fixed
      :bordered="false"
    /> -->
    <slot />
    <!-- 自定义tabbr -->
    <WatTabbar :route="route" />
    <wd-notify />
    <wd-toast />
    <wd-message-box />
    <!-- <privacy-popup /> -->
  </wd-config-provider>
</template>

<script lang="ts" setup>
import type { ConfigProviderThemeVars } from 'wot-design-uni'
import WatTabbar from '@/components/wat-tabbar/WatTabbar.vue'
import { useCommonStore } from '@/store'
const commonStore = useCommonStore()
const themeVars = reactive<ConfigProviderThemeVars>({
  colorTheme: '#ff6600',
  buttonPrimaryBgColor: '#ff6600',
  swiperRadius: '0',
  cellWrapperPadding: '1rem',
})
//注意 使用pinia可以 watch不行
const route = computed(() => commonStore.appConfig.curTabbarRoute)
onShow(() => {

  uni.hideTabBar()

  const pages = getCurrentPages()
  const currentPage = pages[pages.length - 1]
  commonStore.setCurTabbarRoute(currentPage.route)
})
</script>

<script lang="ts">
export default {
  options: {
    addGlobalClass: true,
    virtualHost: true,
    styleIsolation: 'shared',
  },
}
</script>
