<template>
  <wd-tabbar
    :model-value="activeTabbar.pagePath"
    placeholder
    bordered
    safe-area-inset-bottom
    fixed
    activeColor="#ff6600"
    inactiveColor="#707070"
    @change="handleTabbarChange"
  >
    <template v-for="(item, index) in filteredTabbarList" :key="item.uniqueId">
      <wd-tabbar-item
        v-if="item.iconType === 'wot'"
        :name="item.pagePath"
        :title="item.text"
        :icon="item.icon"
      />
      <wd-tabbar-item
        v-else-if="item.iconType === 'local'"
        :name="item.pagePath"
        :title="item.text"
      >
        <template #icon>
          <image
            class="w-24px h-24px"
            :src="
              item.active ? resolveImgPath(item.selectedIconPath) : resolveImgPath(item.iconPath)
            "
          ></image>
        </template>
      </wd-tabbar-item>
    </template>
  </wd-tabbar>
</template>

<script lang="ts" setup>
import { useTabbar } from './useTabbar'
const props = defineProps({
  route: String,
})
const { activeTabbar, filteredTabbarList, setTabbarItemActive } = useTabbar()
const resolveImgPath = (path: string): string => {
  return '/' + path
}
const handleTabbarChange = async ({ value }: { value: string }) => {
  uni.switchTab({
    url: `/${value}`,
  })
}
// 监听路由变化自动更新活跃tabbar
watch(
  () => props.route,
  (newVal) => {
    if (newVal) {
      setTabbarItemActive(newVal)
    }
  },
  { immediate: true },
)
</script>

<style lang="scss" scoped>
:deep(.wd-tabbar-item__body-icon) {
  font-size: 24px !important;
}
</style>
